<template>
  <b-card>
    <validation-observer
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <b-col md="6">
          <!-- Title -->
          <validation-provider
            #default="validationContext"
            name="Title"
            rules="required"
          >
            <b-form-group
              label="Title (En)"
              label-for="name"
            >
              <b-form-input
                id="title"
                v-model="newsForm.title_en"

                :state="getValidationState(validationContext)"
                trim
                placeholder="Title"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Title -->
          <validation-provider
            #default="validationContext"
            name="Title"
            rules="required"
          >
            <b-form-group
              label="Title (Ar)"
              label-for="title"
            >
              <b-form-input
                id="title"
                v-model="newsForm.title_ar"

                :state="getValidationState(validationContext)"
                trim
                placeholder="Title"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            rules="required"
            name="Description"
          >
            <b-form-group
              label="Description (En)"
              label-for="description"
            >
              <b-form-textarea
                id="description"
                v-model="newsForm.description_en"
                trim
                type="text"
                placeholder="Description"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            rules="required"
            name="Description"
          >
            <b-form-group
              label="Description (Ar)"
              label-for="description"
            >
              <b-form-textarea
                id="description"
                v-model="newsForm.description_ar"
                trim
                type="text"
                placeholder="Description"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            rules="required"
            name="Countries"
          >
            <b-form-group
              label="Country"
              label-for="Countries"
            >
              <v-select
                v-model="newsForm.country_id"
                :options="countries"
                :filterable="false"
                label="name"
                :reduce="countries => countries.id"
                :state="getValidationState(validationContext)"
                @search="searchCountries"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12">
          <b-media>

            <b-media-aside>
              <b-col
                v-for="(image,index) in newsForm.images"
                :key="image.id"
                cols="3"
              >

                <div class="d-flex">
                  <span
                    class="deleteImgIcon"
                    @click="deleteImg(image.id , index)"
                  >
                    <feather-icon icon="XIcon" />
                  </span>
                  <b-img
                    ref="previewEl"
                    v-img
                    :src="image.url"
                    class="ml-1 typeImg"
                    height="auto"
                    width="100"
                  />

                </div>
              </b-col>

              <!--/ image -->
            </b-media-aside>

            <b-media-body class="mt-75 ml-75">
              <!-- upload button -->
              <b-button
                variant="primary"
                size="sm"
                class="mb-75 mr-75"
                @click="selectFile()"
              >
                Upload Images
              </b-button>
              <validation-provider
                #default="validationContext"
                name="type image"
              >
                <b-form-group>
                  <b-form-file
                    ref="refInputEl"
                    accept=".jpg, .png"
                    :hidden="true"
                    plain
                    multiple
                    :state="getValidationState(validationContext)"
                    @change="changeProfile($event) "
                  />
                </b-form-group>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            <!--/ upload button -->
            </b-media-body>
          </b-media>
        </b-col>

        <b-col
          v-if="errorMsg"
          cols="12"
        >
          <p class="text-danger">
            {{ errorMsg }}
          </p>
        </b-col>
        <!-- Server Errors -->
        <b-col
          v-if="Object.values(errors).length > 0"
          cols="12"
        >
          <p
            v-for="(error, key, index) in errors"
            :key="index"
            class="text-danger small"
          >
            {{ error.toString() }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >  <b-button
             v-if="!loader && $route.params.id"
             variant="primary"
             class="mr-1"
             :disabled="invalid || Object.values(errors).length > 0 "
             @click="addNews()"
           >
             Save Changes
           </b-button>
          <b-button
            v-if="!loader && !$route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0 || images.length===0"
            @click="addNews()"
          >
            Add
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from 'axios'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line no-unused-vars
import _ from 'lodash'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      id: this.$route.params.id,
      isVoucher: false,
      required,
      allSelected: [],
      indeterminate: false,
      countries: [],
      users: [],
      loader: false,
      validationErrors: {},
      errorMsg: '',
      errors: {},
      file: '',
    }
  },
  setup() {
    const { getValidationState } = formValidation()
    // eslint-disable-next-line camelcase
    const images = ref([])

    const newsForm = ref({
      images: [],
    })
    return {
      getValidationState,
      newsForm,
      images,
    }
  },
  mounted() {
    this.showNews()
    this.getCountries()
  },
  methods: {
    selectFile() {
      this.$refs.refInputEl.$el.click()
    },
    async searchCountries(searchQuery) {
      await axios.get(`admin/countries?search=${searchQuery}`).then(res => {
        if (res.status === 200) {
          this.countries = res.data?.data.countries
        }
      })
    },
    async getCountries() {
      await axios.get('admin/countries').then(res => {
        if (res.status === 200) {
          this.countries = res.data?.data.countries
        }
      })
    },
    changeProfile(e) {
      // eslint-disable-next-line prefer-destructuring
      // this.images = e.target.files[0]
      const input = e.target
      if (input.files) {
        for (let i = 0; i < input.files.length; i += 1) {
          const url = URL.createObjectURL(input.files[i])
          const img = new Image()
          img.src = window.URL.createObjectURL(input.files[i])
          img.onload = () => {
            this.newsForm.images.push({ url })
            this.images.push(input.files[i])
          }
        }

        // eslint-disable-next-line prefer-destructuring
      }
    },
    deleteImg(id, index) {
      if (id) {
        axios.get(`admin/delete_campaign_media/${id}`).then(res => {
          if (res.status === 200) {
            this.showNews()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Deleted Succesfully',
                icon: 'BellIcon',
                variant: 'success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Server Error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }
        }).catch(() => {
        // this.formData = new FormData()
        }).finally(() => {
          this.loader = false
        })
      } else {
        this.newsForm.images.splice(index, 1)
        this.images.splice(index, 1)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted Succesfully',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      }
    },

    showNews() {
      if (this.$route.params.id) {
        axios.get(`admin/blogs/${this.$route.params.id}`).then(res => {
          this.newsForm = res.data.data.blog
        })
      } else {
        return false
      }
      return true
    },
    addNews() {
      if (this.$route.params.id) {
        this.loader = true
        const formData = new FormData()

        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in this.newsForm) {
          if (key !== 'images') {
            formData.append(key, this.newsForm[key])
          }
        }
        if (this.images) {
          // eslint-disable-next-line no-restricted-syntax
          for (const image of this.images) {
            formData.append('images[]', image)
          }
        }

        axios.post(`admin/blogs/${this.$route.params.id}`, formData).then(res => {
          if (res.status === 200) {
            this.$router.push({ name: 'news' })
            this.$toasted.show('Updated Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          if (error.response.status === 500) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ooops!!! An Unexpected Internal server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          } else if (error.response.status === 422) {
            if (error.response.data.errors !== undefined) {
              this.errorMsg = error.response.data.errors
            } else {
              this.errorMsg = error.response.data.message
            }
          }
        }).finally(() => {
          this.loader = false
        })
      } else {
        this.loader = true
        const formData = new FormData()
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in this.newsForm) {
          if (key !== 'images') {
            formData.append(key, this.newsForm[key])
          }
        }
        if (this.images) {
          // eslint-disable-next-line no-restricted-syntax
          for (const image of this.images) {
            formData.append('images[]', image)
          }
        }

        axios.post('admin/blogs', formData).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$router.push({ name: 'news' })
            this.$toasted.show('Created Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          if (error.response.status === 500) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ooops!!! An Unexpected Internal server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          } else if (error.response.status === 422) {
            if (error.response.data.errors !== undefined) {
              this.errorMsg = error.response.data.errors
            } else {
              this.errorMsg = error.response.data.message
            }
          }
        }).finally(() => {
          this.loader = false
        })
      }
    },
  },

}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  .deleteImgIcon{
    cursor: pointer;
    color: grey;
  }
  video{width: 200px;
  margin: 0px 10px}

  </style>
